<template>
  <v-card>
    <v-card-title class="text-h4 grey lighten-2">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <span class="me-2">
            {{ $t('broadcasts.broadcastList') }}
          </span>
        </v-col>
        <v-col class="text-end" cols="12" lg="6" md="6" sm="12" xs="12">
          <v-progress-circular
            v-if="!serverConnectionStatus || !isLoadContactsDone"
            indeterminate
            size="32"
            color="primary"
          ></v-progress-circular>
          <v-btn
            v-else
            :disabled="!serverConnectionStatus || !isLoadContactsDone"
            color="#1F979E"
            @click="
              addBroadcastDialog = true
              newBroadcast += 1
            "
          >
            <v-icon color="white" left>
              {{ icons.mdiPlus }}
            </v-icon>
            <span class="white--text">{{ $t('broadcasts.createBroadcast') }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-tooltip v-if="statusChannelsBanner && bannerAlertText" bottom color="warning">
        <template #activator="{ on, attrs }">
          <v-icon v-bind="attrs" color="warning" v-on="on">
            {{ icons.mdiAlert }}
          </v-icon>
        </template>
        <span>{{ bannerAlertText }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <div
          :class="[`text-subtitle-2`, `ml-2`, `mt-0`, `mb-2`, `font-weight-bold`, `red--text`]"
          v-html="$t('broadcasts.notesSendingBroadcasts')"
        ></div>
      </v-row>
      <div class="mb-2 mt-6">
        <v-row dense class="justify-space-between">
          <span class="text-h6 mb-4 font-weight-semibold text--white">
            {{ $t('broadcasts.broadcastHistory') }}
          </span>
          <v-card-actions>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              :return-value.sync="dates"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Filter"
                  :prepend-icon="icons.mdiCalendar"
                  :append-icon="icons.mdiClose"
                  readonly
                  outlined
                  v-bind="attrs"
                  class=""
                  dense
                  v-on="on"
                >
                  <template v-slot:append>
                    <v-icon @click="resetDates">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </template>
                </v-text-field>
              </template>

              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="#ffeeee" @click="menu = false">
                  <span class="error--text">{{ $t('cancel') }}</span>
                </v-btn>
                <v-btn text color="success" @click="getBroadcasts()"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-card-actions>
        </v-row>
      </div>
      <v-row :style="isMini() ? '' : `margin-bottom:-24px`">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="filterStatus"
            :items="filterBroadcasts"
            label="Filter"
            outlined
            dense
            @change="applyFilter()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="selectedChannel"
            :items="filterChannels"
            :label="$t('broadcasts.channelFilter')"
            item-text="label"
            item-value="phone_number"
            outlined
            dense
            @change="applyFilterChannel()"
          ></v-select>
        </v-col>
        <!-- <v-col cols="12" md="1">
          <v-btn
            color="primary"
            block
            :disabled="!serverConnectionStatus || broadcastListTable.length == 0"
            @click="dialogDeleteBroadcastBulk()"
          >
            <v-icon>{{ icons.mdiTrashCan }}</v-icon>
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn block color="secondary" @click="exportTableListStatitic()">
            <span class="white--text">
              <vue-excel-xlsx
                :data="broadcastListAll"
                :columns="columnsbroadcastAll"
                :file-name="'BroadcatList'"
                :file-type="'xlsx'"
                :sheet-name="'BroadcatList'"
                class="primary-text"
              >
                {{ $t('exportToCsv') }}
              </vue-excel-xlsx>
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn block color="secondary" @click="$router.push({ name: 'broadcast-scheduled' })">
            <!-- <v-icon color="primary" left>
              {{ icons.mdiExport }}
            </v-icon> -->
            <span class="white--text">{{ $t('broadcasts.broadcastTimeline') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Table -->
    <v-data-table
      v-model="broadcastListTable"
      item-key="broadcastId"
      :headers="headers"
      :items="broadcastList"
      :search="search"
      :items-per-page="10"
      :loading="loadingTable"
      loading-text="Loading... Please wait"
      show-select
      multi-sort
      return-object
      :sort-by="['broadcast_time']"
      :sort-desc="[true]"
      class="table-broadcast"
      :footer-props="{
        'items-per-page-text': 'Item Per Page',
        'items-per-page-options': [5, 10, 30, 50, 100],
      }"
      checkbox-color="#111B21"
    >
      <!-- Channel -->
      <template slot="no-data">
        <div class="mx-auto mb-10">
          <v-img class="mx-auto" width="256" length="256" src="@/assets/images/no broadcast list.png"></v-img>
          <span class="text-h6 text--white font-weight-bold">{{ $t('broadcasts.emptyList') }}</span>
        </div>
      </template>
      <template v-slot:header.data-table-select="{ on, props }">
        <div class="d-flex mx-auto">
          <v-simple-checkbox :ripple="false" color="#111B21" v-bind="props" v-on="on"></v-simple-checkbox>
          <v-icon
            class="mx-auto"
            v-bind="props"
            :disabled="!serverConnectionStatus || broadcastListTable.length == 0"
            color="#FF4C51"
            size="24"
            v-on="on"
            @click="dialogDeleteBroadcastBulk()"
          >
            {{ icons.mdiTrashCan }}
          </v-icon>

          <!-- <v-btn
            color="primary"
            fab
            :ripple="false"
            v-bind="props"
            v-on="on"

          >
          </v-btn> -->
        </div>
      </template>
      <template #[`item.progress`]="{ item }">
        <div style="width: 60px">
          <v-progress-linear
            v-if="item.status !== 'Draft'"
            :value="item.progress"
            color="primary"
            height="20"
            block
            dark
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) > 100 ? 100 : Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </div>
      </template>
      <template #[`item.channel`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block font-weight-semibold text-truncate text--primary">{{
              item.sender.label.startsWith('-') ||
              item.sender.label.includes('Default') ||
              item.sender.label.includes('62')
                ? item.sender.label_server
                : item.sender.label
            }}</span>
            <span class="text-xs">{{ item.sender.phone_number }}</span>
          </div>
        </div>
      </template>
      <template #[`item.broadcast_time`]="{ item }">
        <span class="text-no-wrap">{{ $moment(item.broadcast_time).format('DD MMM YYYY HH:mm') }}</span>
      </template>
      <template #[`item.time`]="{ item }">
        <span class="text-no-wrap">{{ $moment(item.broadcast_time).format('DD MMM YYYY HH:mm') }}</span>
      </template>
      <!-- status -->
      <template #[`item.status`]="{ item }">
        <!-- :class="`${getTextColor(item.status)}--text`" -->
        <v-chip :color="getChipColor(item.status)" class="v-chip-${isDark ? 'dark' : 'light'}-bg" small>
          <span :style="`color: ${getTextColor(item.status)};`">
            {{ item.status }}
          </span>
        </v-chip>

        <v-tooltip v-if="item.status === 'Processing' || item.status === 'Scheduled'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#1F979E" icon v-bind="attrs" v-on="on" @click="dialogPauseBroadcast(item)">
              <v-icon :style="{ color: `${isDark ? 'white' : 'gray'}` }">
                {{ icons.mdiPauseCircle }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('broadcasts.pauseBroadcast') }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.status === 'Paused'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#1F979E" icon v-bind="attrs" v-on="on" @click="dialogResumeBroadcast(item)">
              <v-icon :style="{ color: `${isDark ? 'white' : 'gray'}` }">
                {{ icons.mdiPlayCircle }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('broadcasts.resumeBroadcast') }}</span>
        </v-tooltip>
      </template>
      <!-- statistic -->
      <template #[`item.statistic`]="{ item }">
        <v-chip color="#FBF4E9" small class="chip-margin-left">
          <span style="color: #cfa007">{{ $t('broadcasts.sent') }} : {{ item.statistic.sent || 0 }}</span>
        </v-chip>
        <v-chip color="#d3e8f3" small class="chip-margin-left">
          <span style="color: #2c496e"> {{ $t('broadcasts.received') }} : {{ item.statistic.received || 0 }} </span>
        </v-chip>
        <v-chip color="#E7F7EE" small class="chip-margin-left">
          <span style="color: #265c01"> {{ $t('broadcasts.read') }} : {{ item.statistic.read || 0 }} </span>
        </v-chip>
        <v-chip color="#E9F5F5" small class="chip-margin-left">
          <span style="color: #f15858"> {{ $t('broadcasts.replied') }} : {{ item.statistic.replied || 0 }} </span>
        </v-chip>
        <v-chip color="red" small class="chip-margin-left">
          <span style="color: red"> {{ $t('broadcasts.failed') }} : {{ item.statistic.failed || 0 }} </span>
        </v-chip>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-menu offset-y :close-on-content-click="true" :close-on-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
              <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
            </v-btn>
          </template>
          <v-list outlined>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="openStatDialog(item)">
                  <v-icon>{{ icons.mdiChartBar }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('broadcasts.statisticBroadcast') }}</span>
            </v-tooltip>

            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="retryBroadcast(item)"
                >
                  <v-icon>{{ icons.mdiRefresh }}</v-icon>
                </v-btn>
              </template>
              <span>Resend</span>
            </v-tooltip> -->

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="openDialog(item)">
                  <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('broadcasts.detailBroadcast') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="serverConnectionStatus && item.cron_status !== false"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogDeleteBroadcast(item)"
                >
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('broadcasts.deleteBroadcast') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.is_draft && serverConnectionStatus"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editBroadcasts(item)"
                >
                  <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('broadcasts.editBroadcast') }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.status === 'Processing'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="dialogPauseBroadcast(item)">
                  <v-icon>{{ icons.mdiPauseCircle }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('broadcasts.pauseBroadcast') }}</span>
            </v-tooltip>
            <v-tooltip v-if="item.status === 'Pause'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="dialogResumeBroadcast(item)">
                  <v-icon>{{ icons.mdiPlayCircle }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('broadcasts.resumeBroadcast') }}</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <!-- detail broadcast dialog -->
    <v-dialog v-model="detailDialog" width="650">
      <v-card class="d-flex align-center">
        <div class="d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-contact">
          <div class="mx-auto pa-3" style="max-width: 320px">
            <preview-message :message-preview="messagePreview"></preview-message>
          </div>
          <v-divider :vertical="true"></v-divider>
          <div>
            <v-card-title> Detail Broadcast </v-card-title>
            <v-card-text>
              <table class="billing-address-table">
                <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('broadcasts.broadcastName') }}
                    </p>
                  </td>
                  <td>
                    <p><strong> : </strong>{{ singleBroadcastName }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('broadcasts.messageTemplate') }}
                    </p>
                  </td>
                  <td>
                    <p><strong> : </strong>{{ singleMessageTemplates }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('broadcasts.numberContacts') }}
                    </p>
                  </td>
                  <td>
                    <p><strong> : </strong>{{ singleNumberOfContacts }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('broadcasts.executionDatetime') }}
                    </p>
                  </td>
                  <td>
                    <p><strong> : </strong>{{ singleExecutionDate }}</p>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('broadcasts.sendingSpeed') }}
                    </p>
                  </td>
                  <td>
                    <p><strong> : </strong>{{ singleSendSpeed }}</p>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      Track Link
                    </p>
                  </td>
                  <td>
                    <p><strong> : </strong>{{ singleTrackLink }}</p>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      Allow Unsubscribe
                    </p>
                  </td>
                  <td>
                    <p><strong> : </strong>{{ singleUnsubscribe }}</p>
                  </td>
                </tr> -->
              </table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="detailDialog = false">
                {{ $t('close') }}
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- statistic broadcast dialog -->
    <v-dialog v-model="statisticDialog" width="870" scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('broadcasts.broadcastStatistic') }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" lg="3" md="3" sm="3">
              <v-list class="pt-1">
                <v-list-item
                  v-for="(data, index) in detailListStatus"
                  :key="'status-' + index"
                  class="d-flex px-0 mt-4"
                >
                  <v-avatar
                    size="40"
                    rounded
                    :color="data.avatarColor"
                    :class="`v-avatar-${isDark ? 'dark' : 'light'}-bg ${
                      isDark ? 'white' : data.avatarColor
                    }--text me-3`"
                  >
                    {{ data.value }}
                  </v-avatar>

                  <div class="d-flex align-center flex-gcontact-1 flex-wrap">
                    <div class="me-2">
                      <h4 class="font-weight-semibold">
                        {{ data.name }}
                      </h4>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" lg="9" md="9" sm="9" class="mt-1">
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filterDetail"
                    :append-icon="icons.mdiFilter"
                    label="filter"
                    single-line
                    hide-details
                    dense
                    outlined
                    return-object
                    :items="filterItems"
                    class="search-in-detail-status"
                    @change="applyFilterDetail()"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn block color="primary" @click="exportTableListStatitic()">
                    <v-icon left>
                      {{ icons.mdiExport }}
                    </v-icon>
                    {{ $t('exportToCsv') }}
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="searchDetail"
                    :append-icon="icons.mdiMagnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                    outlined
                    class="search-in-detail-status"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-data-table
                :headers="detailHeaders"
                :items="detailListContact"
                :items-per-page="currentRowsPerPage"
                :footer-props="{
                  'items-per-page-text': 'Item per page',
                  'items-per-page-options': [5, 10, 30, 50, 100],
                }"
                :server-items-length="totalContacts"
                class="table-broadcast"
                :page="currentPage"
                :loading="loadingTableStatistic"
                @update:page="updatePagination($event)"
                @update:items-per-page="updateRowsPerPage($event)"
              >
                <template #[`item.phone_number`]="{ item }">
                  <div class="d-flex align-center">
                    <div class="d-flex flex-column ms-3">
                      <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.name }}</span>
                      <span class="text-xs">{{ item.phone_number }}</span>
                    </div>
                  </div>
                </template>
                <template #[`item.link_clicked`]="{ item }">
                  <div class="d-flex align-center">
                    <div class="d-flex flex-column ms-3">
                      <span v-for="link in item.link_clicked" :key="item.phone_number + link" class="text-xs">{{
                        link
                      }}</span>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="primary" @click="retryBroadcast(itemResendModal)"> Resend </v-btn> -->
          <v-btn color="error" @click="statisticDialog = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- addBroadcast Dialog -->
    <v-dialog v-model="addBroadcastDialog" persistent width="1000" scrollable>
      <template v-slot:default="">
        <v-card elevation="0">
          <v-toolbar color="#1F979E33">
            <div class="text-h5 font-weight-bold ml-2" :style="{ color: '#1F979E' }">
              {{ $t('broadcasts.createBroadcast') }}
            </div>
            <v-spacer></v-spacer>
            <v-btn class="pa-8" icon @click="addBroadcastDialog = false">
              <v-icon color="#FF4C51">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <add-broadcast
              :key="newBroadcast"
              :data-contacts-from-api="dataContactsFromApi"
              @close-dialog="closeDialog()"
            ></add-broadcast>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <!-- EditBroadcast Dialog -->
    <v-dialog v-model="editBroadcastDialog" width="900" scrollable>
      <template v-slot:default="">
        <v-card elevation="0">
          <v-toolbar color="primary">
            <div class="text-h5 white--text">
              {{ $t('broadcasts.editBroadcast') }}
            </div>
            <v-spacer></v-spacer>
            <v-btn class="pa-8" icon @click="editBroadcastDialog = false">
              <v-icon x-large color="white">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <add-broadcast
              :key="editBroadcastKey"
              :is-draft="true"
              :broadcast-data="broadcastEditData"
              :from-contacts="fromContacts"
              :from-list="fromList"
              :contacts-from-wa="contactsFromWa"
              :list-selected="listSelected"
              :data-contacts-from-api="dataContactsFromApi"
              @close-dialog="closeDialog()"
            ></add-broadcast>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <!-- dialog loading -->
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="320">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          {{ loadingText }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialog Delete -->
    <v-dialog v-model="deleteDialog" persistent width="500">
      <v-card elevation="0">
        <v-card-title>
          <div class="white--text">
            {{ $t('broadcasts.confirm') }}
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-3 mt-2 mb-5">
          {{ $t('broadcasts.confirmDelete') }} {{ deletedBroadcastData.length }} Broadcast ?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="#ffeeee" dark @click="deleteDialog = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn elevation="0" color="success" dark @click="deleteBroadcasts()">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog Resume -->
    <v-dialog v-model="resumeDialog" persistent width="500">
      <v-card elevation="0">
        <v-card-title>
          <div class="white--text">
            {{ $t('broadcasts.confirm') }}
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-3 mt-2 mb-5"> {{ $t('broadcasts.confirmResume') }} ? </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="#ffeeee" dark @click="resumeDialog = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn elevation="0" color="success" dark @click="resumeBroadcasts()">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog Pause -->
    <v-dialog v-model="pauseDialog" persistent width="500">
      <v-card elevation="0">
        <v-card-title>
          <div class="white--text">
            {{ $t('broadcasts.confirm') }}
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-3 mt-2 mb-5"> {{ $t('broadcasts.confirmPause') }} ? </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="#ffeeee" dark @click="pauseDialog = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn elevation="0" color="success" dark @click="pauseBroadcasts()">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog redirect -->
    <v-dialog v-model="redirectDialog" persistent width="400">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          {{ $t('broadcasts.loginExpired') }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable */
import { default as clientPocketBase } from '@/helpers/pocketbase'

import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiAlert,
  mdiCalendar,
  mdiChartBar,
  mdiClose,
  mdiCloseCircle,
  mdiDeleteOutline,
  mdiDotsHorizontalCircle,
  mdiExport,
  mdiEyeOutline,
  mdiFilter,
  mdiMagnify,
  mdiPauseCircle,
  mdiPencilOutline,
  mdiPlayCircle,
  mdiPlus,
  mdiRefresh,
  mdiTrashCan,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// dio: ini gw coba ganti ke yg lebih efisien
import orderBy from 'lodash/orderBy'
import uniq from 'lodash/uniq'
///////////////////////////////////////////////////////
// import custom
import axios from 'axios'
import XLSX from 'xlsx'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import AddBroadcast from './AddBroadcast.vue'
import PreviewMessage from './PreviewMessage.vue'

export default {
  components: { AddBroadcast, PreviewMessage },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')
    const searchDetail = ref('')
    const statusColor = {
      Processing: '#FBF4E9',
      Done: '#E7F7EE',
      Cancelled: '#F7E5E6',
      Draft: '#707683',
      Waiting: '#E9F5F5',
    }
    const statusColorText = {
      Processing: '#FFB946',
      Done: '#2ED47A',
      Cancelled: '#D11A2A',
      Draft: '#707683',
      Waiting: '#1F979E',
    }

    return {
      icons: {
        mdiAlert,
        mdiMagnify,
        mdiClose,
        mdiDeleteOutline,
        mdiPlus,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiCalendar,
        mdiDotsHorizontalCircle,
        mdiPencilOutline,
        mdiTrashCan,
        mdiPauseCircle,
        mdiPlayCircle,
        mdiFilter,
        mdiRefresh,
        mdiClose,
      },
      isDark,
      search,
      statusColor,
      statusColorText,
      searchDetail,
      headers: [],
      detailHeaders: [],
    }
  },
  mixins: [pouchDbMixin],
  data() {
    return {
      menu: false,
      dates: [this.$moment().subtract(30, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      filterBroadcasts: ['All', 'Done', 'Processing', 'Paused', 'Resumed'],
      filterChannels: [
        {
          label: 'All Channels',
          phone_number: 'all',
        },
      ],
      selectedChannel: 'all',
      filterStatus: 'All',
      addBroadcastDialog: false,
      newBroadcast: 1,
      loadingTable: true,
      detailDialog: false,
      statisticDialog: false,
      detailBroadcast: {},
      detailListStatus: [],
      detailListContact: [],
      singleBroadcastName: '',
      singleMessageTemplates: '',
      singleNumberOfContacts: '',
      singleExecutionDate: '',
      singleSendSpeed: '',
      singleTrackLink: '',
      singleUnsubscribe: '',
      broadcastList: [],
      broadcastListAll: [],
      loadingDialog: false,
      loadingText: 'Loading',
      messagePreview: {},
      deleteDialog: false,
      pauseDialog: false,
      resumeDialog: false,
      currentPage: 1,
      deletedBroadcastData: [],
      resumedBroadcastData: [],
      pausedBroadcastData: [],
      broadcastListTable: [],
      redirectDialog: false,
      dataContactsFromApi: null,
      isLoadContactsDone: false,

      intervalId: null,
      // for edit
      editBroadcastDialog: false,
      editBroadcastKey: 1,
      fromContacts: false,
      fromList: false,
      contactsFromWa: [],
      listSelected: [],
      broadcastEditData: {},
      itemResendModal: {},
      filterItems: [],
      //exportColumn
      columnsbroadcastAll: [
        {
          label: 'Channel',
          field: 'channel',
        },
        {
          label: 'Broadcast Name',
          field: 'broadcast_name',
        },
        {
          label: 'Broadcast Time',
          field: 'broadcast_time',
        },
        {
          label: 'Delivered',
          field: 'delivered',
        },
        {
          label: 'Recipients',
          field: 'recipients',
        },
        {
          label: 'Skipped',
          field: 'failed',
        },
        {
          label: 'Status',
          field: 'status',
        },
      ],
      columnsbroadcastStatistic: [
        {
          text: 'No',
          value: 'no',
        },
        {
          text: 'Channel',
          value: 'channel',
        },
        {
          text: 'Broadcast Name',
          value: 'broadcast_name',
        },
        {
          text: 'Broadcast Time',
          value: 'broadcast_time',
        },
        {
          text: 'Delivered',
          value: 'delivered',
        },
        {
          text: 'Recipients',
          value: 'recipients',
        },
        {
          text: 'Skipped',
          value: 'failed',
        },
        {
          text: 'Status',
          value: 'status',
        },
        // {
        //   text: 'Info',
        //   value: 'errInfo',
        // },
      ],
      exportTableListStatiticName: '',
      detailListContactAll: [],
      filterDetail: { text: 'All', value: 'all' },
      watcher: null,
      databaseBroadcastChanges: null,
      totalContacts: 0,
      loadingTableStatistic: 0,
      currentRowsPerPage: 5,
      selectedBroadcastId: null,
      options: {},
      instances: [],
    }
  },
  beforeDestroy() {
    // this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_BROADCAST)
    if (this.intervalId) clearInterval(this.intervalId)
  },
  async mounted() {
    // subscribe any changes in broadcast to pocketbase
    clientPocketBase.collection('crm_broadcasts').subscribe('*', e => this.getBroadcasts())
    clientPocketBase.collection('crm_broadcast_messages').subscribe('*', e => this.getBroadcastMessages(e))
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.instances = instances?.value || []
    // const REALTIME_URL = 'wss://realtime.superwa.io/socket'
    // const client = new RealtimeClient(REALTIME_URL)
    // client.connect()
    // client.onOpen(() => console.log('Socket opened.'))
    // client.onClose(() => console.log('Socket closed.'))
    // client.onError(e => console.log('Socket error', e))

    // this.databaseBroadcastChanges = client.channel(`realtime:${this.user.sub_id}:broadcasts`)
    // this.databaseBroadcastChanges.on('*', e => this.updateBroadcast(e))
    // this.databaseBroadcastChanges.subscribe()
    this.isLoadContactsDone = true
    this.headers = [
      { text: this.$t('broadcasts.channel'), value: 'channel' },
      { text: this.$t('broadcasts.broadcastName'), value: 'broadcast_name', sortable: true },
      { text: this.$t('broadcasts.broadcastTime'), value: 'broadcast_time' },
      { text: this.$t('broadcasts.progress'), value: 'progress', sortable: false },
      { text: 'Status', value: 'status', sortable: true },
      { text: this.$t('broadcasts.statistics'), value: 'statistic', sortable: false },
      {
        text: this.$t('broadcasts.recipients'),
        value: 'recipients',
        sortable: false,
      },
      { text: this.$t('broadcasts.failed'), value: 'failed', sortable: false },
      { text: this.$t('action'), value: 'actions', sortable: false },
      // { text: this.$t('broadcasts.messageTemplate'), value: 'template', sortable: true },
    ]

    this.filterItems = [
      { text: 'All', value: 'all' },
      { text: this.$t('broadcasts.failed'), value: this.$t('broadcasts.failed') },
      { text: this.$t('broadcasts.unsend'), value: this.$t('broadcasts.unsend') },
      { text: this.$t('broadcasts.sent'), value: this.$t('broadcasts.sent') },
      { text: this.$t('broadcasts.received'), value: this.$t('broadcasts.received') },
      { text: this.$t('broadcasts.read'), value: this.$t('broadcasts.read') },
      { text: this.$t('broadcasts.replied'), value: this.$t('broadcasts.replied') },
    ]
    // await this.mixinConnectBroadcasts(false)
    let conn = await this.checkStatusConnectionServer()
    this.getBroadcasts().then(e => {
      this.loadingTable = false
    })
    // this.intervalIdCheckHealth = window.setInterval(async () => {
    //   this.getBroadcasts(conn).then(e => {
    //     this.loadingTable = false
    //   })
    // }, 5000)
  },
  beforeDestroy() {
    this.databaseBroadcastChanges.unsubscribe()
    this.databaseBroadcastChanges = null
  },
  methods: {
    async updatePagination(data) {
      this.currentPage = data
      this.loadingTableStatistic = true

      let broadcastMessages

      switch (this.filterDetail.value) {
        case 'Sent':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Done' || status = 'Read' || status = 'Received' || status = 'Replied')`,
            },
            { $autoCancel: false },
          )

          // this.detailListContact = this.detailListContactAll.filter(
          //   el => el.status == 'Done' || el.status == 'Read' || el.status == 'Received' || el.status == 'Replied',
          // )
          break

        case 'Received':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Received' || status = 'Replied')`,
            },
            { $autoCancel: false },
          )

          // this.detailListContact = this.detailListContactAll.filter(
          //   el => el.status == 'Received' || el.status == 'Read' || el.status == 'Replied',
          // )
          break

        case 'Read':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Replied')`,
            },
            { $autoCancel: false },
          )
          // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Read' || el.status == 'Replied')
          break

        case 'Replied':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Replied')`,
            },
            { $autoCancel: false },
          )
          // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Replied')
          break

        case 'Failed':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Failed')`,
            },
            { $autoCancel: false },
          )

          // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Failed')
          break

        case this.$t('broadcasts.unsend'):
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Processing' || status = 'Paused')`,
            },
            { $autoCancel: false },
          )
          // this.detailListContact = this.detailListContactAll.filter(el => el.status == this.$t('broadcasts.unsend'))
          break

        default:
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}"`,
            },
            { $autoCancel: false },
          )
          break
      }

      this.totalContacts = broadcastMessages.totalItems

      this.detailListContactAll = broadcastMessages.items.map((el, idx) => {
        let obj = {}
        if (this.currentPage > 1) {
          obj.no = idx + 1 + (this.currentPage - 1) * this.currentRowsPerPage
        } else if (this.currentPage === 1) {
          obj.no = idx + 1
        }
        obj.name = el.sent_to.name
        obj.phone_number = el.sent_to.phone_number
        obj.status = el.status
        obj.errInfo = el.failed_message
        // obj.link_clicked = []
        return obj
      })

      this.detailListContact = [...this.detailListContactAll]

      this.loadingTableStatistic = false
    },
    async updateRowsPerPage(data) {
      this.currentRowsPerPage = data
      this.loadingTableStatistic = true

      let broadcastMessages

      switch (this.filterDetail.value) {
        case 'Sent':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Done' || status = 'Read' || status = 'Received' || status = 'Replied')`,
            },
            { $autoCancel: false },
          )

          // this.detailListContact = this.detailListContactAll.filter(
          //   el => el.status == 'Done' || el.status == 'Read' || el.status == 'Received' || el.status == 'Replied',
          // )
          break

        case 'Received':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Received' || status = 'Replied')`,
            },
            { $autoCancel: false },
          )

          // this.detailListContact = this.detailListContactAll.filter(
          //   el => el.status == 'Received' || el.status == 'Read' || el.status == 'Replied',
          // )
          break

        case 'Read':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Replied')`,
            },
            { $autoCancel: false },
          )
          // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Read' || el.status == 'Replied')
          break

        case 'Replied':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Replied')`,
            },
            { $autoCancel: false },
          )
          // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Replied')
          break

        case 'Failed':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Failed')`,
            },
            { $autoCancel: false },
          )

          // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Failed')
          break

        case this.$t('broadcasts.unsend'):
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Processing' || status = 'Paused')`,
            },
            { $autoCancel: false },
          )
          // this.detailListContact = this.detailListContactAll.filter(el => el.status == this.$t('broadcasts.unsend'))
          break

        default:
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}"`,
            },
            { $autoCancel: false },
          )
          break
      }

      this.totalContacts = broadcastMessages.totalItems

      this.detailListContactAll = broadcastMessages.items.map((el, idx) => {
        let obj = {}
        if (this.currentPage > 1) {
          obj.no = idx + 1 + (this.currentPage - 1) * this.currentRowsPerPage
        } else if (this.currentPage === 1) {
          obj.no = idx + 1
        }
        obj.name = el.sent_to.name
        obj.phone_number = el.sent_to.phone_number
        obj.status = el.status
        obj.errInfo = el.failed_message
        // obj.link_clicked = []
        return obj
      })

      this.detailListContact = [...this.detailListContactAll]

      this.loadingTableStatistic = false
    },
    async resetDates() {
      this.dates = [this.$moment().subtract(30, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      await this.getBroadcasts()
    },
    async retryBroadcast(item) {
      //console.log(item, `INI ITEMNYA`)
      //console.log(Object.keys(item.info).length)
      const receivers = []
      for (const key in item.info) {
        if (
          item.info[key] === 'Server Error' ||
          item.info[key] === 'Limit Retry Message' ||
          item.info[key] === 'Skipped'
        ) {
          receivers.push(key)
        }
      }

      const arrAttachments = []
      const arrAttachmentReply = []
      if (item.message_template.attachments.length !== 0) {
        item.message_template.attachments.forEach(el => {
          arrAttachments.push({
            url: el.uploadURL,
            filename: el.type,
            type: el.name,
          })
        })
      }

      if (item.message_template.on_reply_message.attachments.length !== 0) {
        item.message_template.on_reply_message.attachments.forEach(el => {
          arrAttachmentReply.push({
            url: el.uploadURL,
            filename: el.type,
            type: el.name,
          })
        })
      }

      const obj = {
        sub_id: this.user.sub_id,
        broadcastMsg: {
          broadcast_id: item.broadcastId,
          delete_for_me: item.deleteForMe,
          sender_id: {
            sub_id: item.sender.sub_id,
            instance_id: item.sender.instance_id,
          },
          receiver: '',
          listButtons: item.message_template.listButton,
          buttonHeader: item.message_template.buttonHeader,
          buttonFooter: item.message_template.buttonFooter,
          msg_content: {
            msg: item.message_template.message,
            attachment: arrAttachments,
          },
          on_reply: item.message_template.on_reply,
          msg_reply: {
            msg: item.message_template.on_reply_message.message,
            attachment: arrAttachmentReply,
          },
          sent_time: new Date(item.broadcast_time).getTime() + 0 * 1000,
          retry: 0,
          retry_limit: item.retry_limit,
          processed: false,
          processed_md: false,
          last_queue: false,
          status: 'Processing',
        },
        receivers,
      }

      const resendBroadcast = await this.$store.dispatch('broadcast/resendBroadcast', obj)

      //console.log(resendBroadcast, `INI RESEND`)
    },
    async updateBroadcast(data) {
      // this.broadcastList.forEach(broadcast => {
      //   if (data.record.sub_id === broadcast.sub_id) {
      //     broadcast
      //   }
      // })
      // //console.log(data, `INI DATANYA`)
      // let conn = await this.checkStatusConnectionServer()

      // let findBroadcastIndex

      if (data.type === 'DELETE') {
        // findBroadcastIndex = this.broadcastList.findIndex(broadcast => broadcast.id === data.old_record.id)
        // //console.log(data, `INI DATA`)
        this.broadcastList = this.broadcastList.filter(broadcast => broadcast.id != data.old_record.id)
        // this.getBroadcasts().then(e => {
        //   this.loadingTable = false
        // })
        // //console.log(findDeletedIndex, `INI INDEX DELETED`)
      } else {
        // findBroadcastIndex = this.broadcastList.findIndex(broadcast => broadcast.id === data.record.id)
        // //console.log(data, `INI REKORD`)
        let conn = await this.checkStatusConnectionServer()
        this.getBroadcasts(conn).then(e => {
          this.loadingTable = false
        })
      }
    },
    getChipColor(status) {
      // //console.log(status)
      const statusColor = {
        Processing: '#FBF4E9',
        Done: '#E7F7EE',
        Cancelled: '#F7E5E6',
        Draft: '#edeeef',
        Waiting: '#E9F5F5',
        Scheduling: '#FBF4E9',
      }

      return statusColor[status]
    },
    getTextColor(status) {
      const statusColorText = {
        Processing: '#FFB946',
        Done: '#2ED47A',
        Cancelled: '#D11A2A',
        Draft: '#707683',
        Waiting: '#1F979E',
      }

      return statusColorText[status]
    },
    isMini() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return false
        case 'lg':
          return false
        case 'xl':
          return false
        default:
          return false
      }
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val

      formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

      formatted = formatted.split('"').join('""')
      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')
      return `"${formatted}"`
    },
    exportTableList() {
      return this.broadcastListAll
    },
    startDownload() {
      //console.log('download, start')
    },
    exportTableListStatitic() {
      let exportData = []
      // console.log('sayang')
      if (this.statisticDialog) {
        exportData = this.detailListContact
      } else {
        exportData = this.broadcastListAll
          .filter(el => el.status === 'Done')
          .map(el => {
            return {
              channel: el.channel,
              no: el.no,
              broadcast_name: el.broadcast_name,
              broadcast_time: el.broadcast_time,
              delivered: el.sent,
              recipients: el.recipients,
              skipped: el.failed,
              status: el.status,
            }
          })
      }

      const worksheet = XLSX.utils.json_to_sheet(exportData)
      const workbook = {
        Sheets: { 'Broadcast List': worksheet },
        SheetNames: ['Broadcast List'],
      }

      const excelBuffer = XLSX.write(workbook, { bookType: 'xls', type: 'array' })

      const data = new Blob([excelBuffer], { type: 'application/vnd.ms-excel' })
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(data)
      downloadLink.download = this.exportTableListStatiticName + '-' + new Date() + '-Broadcast-statistic.xls'
      downloadLink.click()
    },
    async openDialog(item) {
      let broadcastData = {}

      let foundBcMessage = await clientPocketBase.collection('crm_broadcast_messages').getFullList(
        400,
        {
          filter: `broadcast_id = "${item.broadcastId}"`,
        },
        { $autoCancel: false },
      )

      for (let index = 0; index < this.broadcastList.length; index++) {
        const element = this.broadcastList[index]
        if (element.broadcastId == item.broadcastId) {
          broadcastData = { ...element }
          index = this.broadcastList.length
        }
      }

      this.detailDialog = true
      this.singleBroadcastName = item.broadcast_name
      this.singleMessageTemplates = item.message_template.template_name
      this.singleNumberOfContacts = foundBcMessage.length
      this.singleExecutionDate = new Date(item.schedule_send)
      this.singleSendSpeed = broadcastData.delay_type
        ? broadcastData.delay_type[0].toUpperCase() + item.delay_type.slice(1)
        : 'Fast'
      this.singleTrackLink = broadcastData.track_link ? 'True' : 'False'
      this.singleUnsubscribe = broadcastData.unsubscribe ? 'True' : 'False'
      this.messagePreview = broadcastData.message_template
    },
    async openStatDialog(item) {
      this.itemResendModal = item
      this.statisticDialog = true
      this.selectedBroadcastId = item.broadcastId
      this.detailHeaders = [
        { text: 'No', value: 'no' },
        { text: this.$t('phoneNumber'), value: 'phone_number' },
        { text: 'Status', value: 'status' },
        { text: 'Info', value: 'errInfo' },
      ]
      this.detailListStatus = [
        { name: this.$t('broadcasts.totalBroadcasts'), avatarColor: 'accent', value: item.recipients },
        {
          name: this.$t('broadcasts.processing'),
          avatarColor: 'primary',
          value: item.statistic.unsent || 0,
        },
        {
          name: this.$t('broadcasts.sending'),
          avatarColor: 'primary',
          value: item.statistic.sent || 0,
        },
        {
          name: this.$t('broadcasts.failed'),
          avatarColor: 'warning',
          value: item.statistic.failed || 0,
        },
        {
          name: this.$t('broadcasts.received'),
          avatarColor: 'success',
          value: item.statistic.received || 0,
        },
        {
          name: this.$t('broadcasts.read'),
          avatarColor: 'success',
          value: item.statistic.read || 0,
        },
        {
          name: this.$t('broadcasts.replied'),
          avatarColor: 'success',
          value: item.statistic.replied || 0,
        },
      ]
      // if (item.total_link > 0) {
      //   this.detailListStatus.push({
      //     name: this.$t('broadcasts.linkClicked'),
      //     avatarColor: 'accent',
      //     value: item.total_link_clicked,
      //   })
      //   this.detailHeaders.push({ text: this.$t('broadcasts.clickedAt'), value: 'link_clicked' })
      // }
      this.loadingTableStatistic = true

      const broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
        this.currentPage,
        this.currentRowsPerPage,
        {
          filter: `broadcast_id = "${item.broadcastId}"`,
        },
        { $autoCancel: false },
      )

      this.totalContacts = broadcastMessages.totalItems

      this.detailListContactAll = broadcastMessages.items.map((el, idx) => {
        let obj = {}
        if (this.currentPage > 1) {
          obj.no = idx + 1 + (this.currentPage - 1) * this.currentRowsPerPage
        } else if (this.currentPage === 1) {
          obj.no = idx + 1
        }
        obj.name = el.sent_to.name
        obj.phone_number = el.sent_to.phone_number
        obj.status = el.status
        obj.errInfo = el.failed_message
        // obj.link_clicked = []
        return obj
      })

      this.detailListContact = [...this.detailListContactAll]
      this.loadingTableStatistic = false

      // //console.log(this.detailListContact, `INI LIST KONTAK`)
      // this.exportTableListStatitic(item)
      this.exportTableListStatiticName = item.broadcast_name
    },
    async sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async getBroadcasts() {
      this.loadingTable = true
      this.filterChannels = [
        {
          label: 'All Channels',
          phone_number: 'all',
        },
      ]
      try {
        this.$refs.menu.save(this.dates)
      } catch {}
      const tempData = await clientPocketBase.collection('crm_broadcasts').getFullList(200, {
        filter: `last_update >= "${new Date(
          new Date(this.dates[0]).setHours(0, 0, 0, 0),
        ).getTime()}" && last_update <= "${new Date(new Date(this.dates[1]).setHours(23, 59, 59, 59)).getTime()}"`,
        $autoCancel: false,
      })

      const broadcasts = []

      for (let i = 0; i < tempData.length; i++) {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/get-broadcast-statistic/${tempData[i].id}`,
        )

        try {
          const getOneContact = await clientPocketBase
            .collection('crm_broadcast_messages')
            .getFirstListItem(`broadcast_id = '${tempData[i].id}'`, {
              $autoCancel: false,
            })

          if (data && getOneContact) {
            const totalItems = data.data.total
            const contact = JSON.parse(JSON.stringify(getOneContact))

            contact.broadcast_time = new Date(contact.schedule_send - new Date().getTimezoneOffset() * 60 * 1000)
              .toISOString()
              .substring(0, 16)
              .replace('T', ' ')

            // change status in detail broadcast

            contact.progress = ((Number(data.data.sent) + Number(data.data.failed)) / Number(totalItems)) * 100

            const foundInstance = this.instances.find(ele => ele?.phone_number === contact.sender.phone_number)
            if (foundInstance) {
              contact.sender.label =
                foundInstance.label.startsWith('-') ||
                foundInstance.label.includes('Default') ||
                foundInstance.label.includes('62')
                  ? foundInstance.label_server
                  : foundInstance.label
            } else {
              contact.sender.label =
                contact.sender.label.startsWith('-') ||
                contact.sender.label.includes('Default') ||
                contact.sender.label.includes('62')
                  ? contact.sender.label_server
                  : contact.sender.label
            }

            contact.channel = contact.sender.label
            this.filterChannels.push(contact.sender)

            contact.broadcast_name = tempData[i].name
            contact.template = contact.message?.template_name
            contact.message_template = contact.message
            contact.status = tempData[i].broadcast_config.is_draft
              ? 'Draft'
              : contact.progress === 100
              ? 'Done'
              : tempData[i].is_paused
              ? 'Paused'
              : tempData[i].status
            contact.recipients = totalItems
            contact.failed = data.data.failed
            contact.sent = data.data.sent
            contact.broadcastId = contact.broadcast_id
            contact.statistic = data.data

            broadcasts.push(contact)

            if (this.selectedBroadcastId && tempData[i].id === this.selectedBroadcastId) {
              this.detailListStatus = [
                { name: this.$t('broadcasts.totalBroadcasts'), avatarColor: 'accent', value: contact.recipients },
                {
                  name: this.$t('broadcasts.processing'),
                  avatarColor: 'primary',
                  value: contact.statistic.unsent || 0,
                },
                {
                  name: this.$t('broadcasts.sending'),
                  avatarColor: 'primary',
                  value: contact.statistic.sent || 0,
                },
                {
                  name: this.$t('broadcasts.failed'),
                  avatarColor: 'warning',
                  value: contact.statistic.failed || 0,
                },
                {
                  name: this.$t('broadcasts.received'),
                  avatarColor: 'success',
                  value: contact.statistic.received || 0,
                },
                {
                  name: this.$t('broadcasts.read'),
                  avatarColor: 'success',
                  value: contact.statistic.read || 0,
                },
                {
                  name: this.$t('broadcasts.replied'),
                  avatarColor: 'success',
                  value: contact.statistic.replied || 0,
                },
              ]

              this.loadingTableStatistic = true
              let broadcastMessages

              switch (this.filterDetail.value) {
                case 'Sent':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Done' || status = 'Read' || status = 'Received' || status = 'Replied')`,
                    },
                    { $autoCancel: false },
                  )

                  // this.detailListContact = this.detailListContactAll.filter(
                  //   el => el.status == 'Done' || el.status == 'Read' || el.status == 'Received' || el.status == 'Replied',
                  // )
                  break

                case 'Received':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Received' || status = 'Replied')`,
                    },
                    { $autoCancel: false },
                  )

                  // this.detailListContact = this.detailListContactAll.filter(
                  //   el => el.status == 'Received' || el.status == 'Read' || el.status == 'Replied',
                  // )
                  break

                case 'Read':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Replied')`,
                    },
                    { $autoCancel: false },
                  )
                  // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Read' || el.status == 'Replied')
                  break

                case 'Replied':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Replied')`,
                    },
                    { $autoCancel: false },
                  )
                  // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Replied')
                  break

                case 'Failed':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Failed')`,
                    },
                    { $autoCancel: false },
                  )

                  // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Failed')
                  break

                case this.$t('broadcasts.unsend'):
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Processing' || status = 'Paused')`,
                    },
                    { $autoCancel: false },
                  )
                  // this.detailListContact = this.detailListContactAll.filter(el => el.status == this.$t('broadcasts.unsend'))
                  break

                default:
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}"`,
                    },
                    { $autoCancel: false },
                  )
                  break
              }

              this.totalContacts = broadcastMessages.totalItems

              this.detailListContactAll = broadcastMessages.items.map((el, idx) => {
                let obj = {}
                if (this.currentPage > 1) {
                  obj.no = idx + 1 + (this.currentPage - 1) * this.currentRowsPerPage
                } else if (this.currentPage === 1) {
                  obj.no = idx + 1
                }
                obj.name = el.sent_to.name
                obj.phone_number = el.sent_to.phone_number
                obj.status = el.status
                obj.errInfo = el.failed_message
                // obj.link_clicked = []
                return obj
              })

              this.detailListContact = [...this.detailListContactAll]
              this.loadingTableStatistic = false
            }
          }
        } catch (err) {}
      }

      this.filterChannels = uniq(this.filterChannels, 'phone_number')
      // sorting desc
      let sortedBroadcast = orderBy(
        broadcasts.map(el => {
          el._id = el.id
          // el.id = el._id

          return el
        }),
        ['broadcast_time'],
        ['desc'],
      )

      this.broadcastListAll = [...sortedBroadcast]
      this.broadcastList = [...sortedBroadcast]
      this.loadingTable = false
    },
    async getBroadcastMessages({ record, action }) {
      if (action !== 'delete') {
        this.loadingTable = true
        this.filterChannels = [
          {
            label: 'All Channels',
            phone_number: 'all',
          },
        ]

        const findBroadcastIndex = this.broadcastListAll.findIndex(
          broadcast => broadcast.broadcastId === record.broadcast_id,
        )

        if (findBroadcastIndex > -1) {
          const { data } = await this.$axios.get(
            `${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/get-broadcast-statistic/${this.broadcastListAll[findBroadcastIndex].broadcastId}`,
          )

          if (data) {
            // change status in detail broadcast
            this.broadcastListAll[findBroadcastIndex].progress =
              ((Number(data.data.sent) + Number(data.data.failed)) / Number(data.data.total)) * 100
            this.broadcastListAll[findBroadcastIndex].failed = data.data.failed
            this.broadcastListAll[findBroadcastIndex].recipients = data.data.total
            this.broadcastListAll[findBroadcastIndex].sent = data.data.sent
            this.broadcastListAll[findBroadcastIndex].statistic = data.data

            if (
              this.selectedBroadcastId &&
              this.broadcastListAll[findBroadcastIndex].broadcastId === this.selectedBroadcastId
            ) {
              this.detailListStatus = [
                {
                  name: this.$t('broadcasts.totalBroadcasts'),
                  avatarColor: 'accent',
                  value: this.broadcastListAll[findBroadcastIndex].recipients,
                },
                {
                  name: this.$t('broadcasts.processing'),
                  avatarColor: 'primary',
                  value: this.broadcastListAll[findBroadcastIndex].statistic.unsent || 0,
                },
                {
                  name: this.$t('broadcasts.sending'),
                  avatarColor: 'primary',
                  value: this.broadcastListAll[findBroadcastIndex].statistic.sent || 0,
                },
                {
                  name: this.$t('broadcasts.failed'),
                  avatarColor: 'warning',
                  value: this.broadcastListAll[findBroadcastIndex].statistic.failed || 0,
                },
                {
                  name: this.$t('broadcasts.received'),
                  avatarColor: 'success',
                  value: this.broadcastListAll[findBroadcastIndex].statistic.received || 0,
                },
                {
                  name: this.$t('broadcasts.read'),
                  avatarColor: 'success',
                  value: this.broadcastListAll[findBroadcastIndex].statistic.read || 0,
                },
                {
                  name: this.$t('broadcasts.replied'),
                  avatarColor: 'success',
                  value: this.broadcastListAll[findBroadcastIndex].statistic.replied || 0,
                },
              ]

              this.loadingTableStatistic = true
              let broadcastMessages

              switch (this.filterDetail.value) {
                case 'Sent':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Done' || status = 'Read' || status = 'Received' || status = 'Replied')`,
                    },
                    { $autoCancel: false },
                  )

                  // this.detailListContact = this.detailListContactAll.filter(
                  //   el => el.status == 'Done' || el.status == 'Read' || el.status == 'Received' || el.status == 'Replied',
                  // )
                  break

                case 'Received':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Received' || status = 'Replied')`,
                    },
                    { $autoCancel: false },
                  )

                  // this.detailListContact = this.detailListContactAll.filter(
                  //   el => el.status == 'Received' || el.status == 'Read' || el.status == 'Replied',
                  // )
                  break

                case 'Read':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Replied')`,
                    },
                    { $autoCancel: false },
                  )
                  // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Read' || el.status == 'Replied')
                  break

                case 'Replied':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Replied')`,
                    },
                    { $autoCancel: false },
                  )
                  // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Replied')
                  break

                case 'Failed':
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Failed')`,
                    },
                    { $autoCancel: false },
                  )

                  // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Failed')
                  break

                case this.$t('broadcasts.unsend'):
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Processing' || status = 'Paused')`,
                    },
                    { $autoCancel: false },
                  )
                  // this.detailListContact = this.detailListContactAll.filter(el => el.status == this.$t('broadcasts.unsend'))
                  break

                default:
                  broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
                    this.currentPage,
                    this.currentRowsPerPage,
                    {
                      filter: `broadcast_id = "${this.selectedBroadcastId}"`,
                    },
                    { $autoCancel: false },
                  )
                  break
              }

              this.totalContacts = broadcastMessages.totalItems

              this.detailListContactAll = broadcastMessages.items.map((el, idx) => {
                let obj = {}
                if (this.currentPage > 1) {
                  obj.no = idx + 1 + (this.currentPage - 1) * this.currentRowsPerPage
                } else if (this.currentPage === 1) {
                  obj.no = idx + 1
                }
                obj.name = el.sent_to.name
                obj.phone_number = el.sent_to.phone_number
                obj.status = el.status
                obj.errInfo = el.failed_message
                // obj.link_clicked = []
                return obj
              })

              this.detailListContact = [...this.detailListContactAll]
              this.loadingTableStatistic = false
            }
          }
        }
        this.broadcastList = [...this.broadcastListAll]
        this.loadingTable = false
      }
    },
    dialogDeleteBroadcast(doc) {
      this.broadcastListTable = []
      this.deletedBroadcastData = [doc]
      this.deleteDialog = true
    },
    dialogDeleteBroadcastBulk() {
      this.deletedBroadcastData = [...this.broadcastListTable]
      this.deleteDialog = true
    },

    dialogPauseBroadcast(doc) {
      this.broadcastListTable = []
      this.pausedBroadcastData = [doc]
      this.pauseDialog = true
    },
    dialogPauseBroadcastBulk() {
      this.pausedBroadcastData = [...this.broadcastListTable]
      this.pauseDialog = true
    },

    dialogResumeBroadcast(doc) {
      this.broadcastListTable = []
      this.resumedBroadcastData = [doc]
      this.resumeDialog = true
    },
    dialogResumeBroadcastBulk() {
      this.resumedBroadcastData = [...this.broadcastListTable]
      this.resumeDialog = true
    },

    async deleteBroadcasts() {
      this.deleteDialog = false
      this.loadingDialog = true
      let dataDeleted = [...this.deletedBroadcastData]
      let delay = dataDeleted.length > 1 ? 100 : 2000

      for (let index = 0; index < dataDeleted.length; index++) {
        const doc = dataDeleted[index]
        this.loadingText = 'Deleting Broadcast :' + doc.broadcast_name

        try {
          // await clientPocketBase.collection('crm_broadcasts').delete(doc.broadcast_id, {
          //   $autoCancel: false,
          // })

          await this.$axios.delete(
            `${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/delete-broadcast/${doc.broadcast_id}`,
          )
        } catch (err) {}

        setTimeout(() => {
          this.loadingDialog = false
        }, delay)
      }

      await this.getBroadcasts()
      this.loadingTable = false
      this.deletedBroadcastData = []
      this.broadcastListTable = []
    },
    async pauseBroadcasts() {
      this.pauseDialog = false
      this.loadingDialog = true
      let dataPaused = [...this.pausedBroadcastData]
      let delay = dataPaused.length > 1 ? 100 : 2000

      try {
        await clientPocketBase.collection('crm_broadcasts').update(this.pausedBroadcastData[0].broadcastId, {
          is_paused: true,
          status: 'Paused',
        })
        await axios.patch(`${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/kala-broadcast-pause`, {
          id: this.pausedBroadcastData[0].broadcastId,
        })
      } catch (error) {
        console.log(error)
      }

      this.loadingDialog = false

      this.pausedBroadcastData = []
      this.broadcastListTable = []
    },
    async resumeBroadcasts() {
      this.resumeDialog = false
      this.loadingText = 'Resuming broadcast'
      this.loadingDialog = true
      let dataResumed = [...this.resumedBroadcastData]
      let delay = dataResumed.length > 1 ? 100 : 2000
      this.loadingDialog = false

      try {
        await clientPocketBase.collection('crm_broadcasts').update(this.resumedBroadcastData[0].broadcastId, {
          is_paused: false,
          status: 'Processing',
        })

        await axios.patch(`${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/kala-broadcast-unpause`, {
          id: this.resumedBroadcastData[0].broadcastId,
        })
      } catch (error) {
        console.log(error)
      }

      this.resumedBroadcastData = []
      this.broadcastListTable = []
      this.loadingText = ''
    },
    async closeDialog() {
      this.addBroadcastDialog = false
      this.editBroadcastDialog = false
      this.editBroadcastKey = this.editBroadcastKey + 1
    },
    editBroadcasts(item) {
      this.editBroadcastKey = this.editBroadcastKey + 1
      this.editBroadcastDialog = true
      if (item.contact_source === 'Whatsapp') {
        this.fromContacts = true
        this.contactsFromWa = item.contacts
      } else {
        this.fromList = true
        this.listSelected = item.contact_list
      }
      this.broadcastEditData = item
    },
    async applyFilterDetail() {
      let filter = this.filterDetail

      this.currentPage = 1
      this.currentRowsPerPage = 5

      this.loadingTableStatistic = true
      let broadcastMessages
      switch (filter.value) {
        case 'Sent':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Done' || status = 'Read' || status = 'Received' || status = 'Replied')`,
            },
            { $autoCancel: false },
          )

          // this.detailListContact = this.detailListContactAll.filter(
          //   el => el.status == 'Done' || el.status == 'Read' || el.status == 'Received' || el.status == 'Replied',
          // )
          break

        case 'Received':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Received' || status = 'Replied')`,
            },
            { $autoCancel: false },
          )

          // this.detailListContact = this.detailListContactAll.filter(
          //   el => el.status == 'Received' || el.status == 'Read' || el.status == 'Replied',
          // )
          break

        case 'Read':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Read' || status = 'Replied')`,
            },
            { $autoCancel: false },
          )
          // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Read' || el.status == 'Replied')
          break

        case 'Replied':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Replied')`,
            },
            { $autoCancel: false },
          )
          // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Replied')
          break

        case 'Failed':
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Failed')`,
            },
            { $autoCancel: false },
          )

          // this.detailListContact = this.detailListContactAll.filter(el => el.status == 'Failed')
          break

        case this.$t('broadcasts.unsend'):
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}" && (status = 'Processing' || status = 'Paused')`,
            },
            { $autoCancel: false },
          )
          // this.detailListContact = this.detailListContactAll.filter(el => el.status == this.$t('broadcasts.unsend'))
          break

        default:
          broadcastMessages = await clientPocketBase.collection('crm_broadcast_messages').getList(
            this.currentPage,
            this.currentRowsPerPage,
            {
              filter: `broadcast_id = "${this.selectedBroadcastId}"`,
            },
            { $autoCancel: false },
          )
          break
      }

      this.totalContacts = broadcastMessages.totalItems

      this.detailListContactAll = broadcastMessages.items.map((el, idx) => {
        let obj = {}
        if (this.currentPage > 1) {
          obj.no = idx + 1 + (this.currentPage - 1) * this.currentRowsPerPage
        } else if (this.currentPage === 1) {
          obj.no = idx + 1
        }
        obj.name = el.sent_to.name
        obj.phone_number = el.sent_to.phone_number
        obj.status = el.status
        obj.errInfo = el.failed_message
        // obj.link_clicked = []
        return obj
      })

      this.detailListContact = [...this.detailListContactAll]

      this.loadingTableStatistic = false
    },
    applyFilterChannel() {
      if (this.selectedChannel !== 'all') {
        if (this.filterStatus !== 'All') {
          this.broadcastList = this.broadcastListAll.filter(
            el => el.status === this.filterStatus && el.sender.phone_number === this.selectedChannel,
          )
        } else if (this.filterStatus === 'All') {
          this.broadcastList = this.broadcastListAll.filter(el => el.sender.phone_number === this.selectedChannel)
        }
      } else if (this.selectedChannel == 'all') {
        this.broadcastList = [...this.broadcastListAll]
        if (this.filterStatus !== 'All') {
          this.broadcastList = this.broadcastListAll.filter(el => el.status === this.filterStatus)
        }
      }
    },
    applyFilter() {
      if (this.filterStatus !== 'All') {
        if (this.selectedChannel !== 'all') {
          this.broadcastList = this.broadcastListAll.filter(
            el => el.status === this.filterStatus && el.sender.phone_number === this.selectedChannel,
          )
        } else if (this.selectedChannel === 'all') {
          this.broadcastList = this.broadcastListAll.filter(el => el.status === this.filterStatus)
        }
      } else if (this.filterStatus === 'All') {
        this.broadcastList = [...this.broadcastListAll]
        if (this.selectedChannel !== 'all') {
          this.broadcastList = this.broadcastListAll.filter(el => el.sender.phone_number === this.selectedChannel)
        }
      }
    },
  },
  computed: {
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    locale() {
      return this.$i18n.locale
    },
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    // instances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   if (inst) return inst.value
    //   return []
    // },
  },
  watch: {
    statisticDialog(val) {
      if (!val) {
        this.detailListContactAll = []
        this.filterDetail = { text: 'All', value: 'all' }
        this.totalContacts = 0
        this.currentRowsPerPage = 5
        this.selectedBroadcastId = null
        this.currentPage = 1
        this.detailListContact = []
      }
    },
    searchDetail() {
      let filter = this.searchDetail

      let data = this.detailListContactAll.filter(el => {
        return (
          el.name.toLowerCase().includes(filter.toLowerCase()) ||
          el.phone_number.includes(filter.toLowerCase()) ||
          el.status.toLowerCase().includes(filter.toLowerCase())
        )
      })
      this.detailListContact = data
    },
    locale() {
      this.headers = [
        { text: this.$t('broadcasts.channel'), value: 'channel' },
        { text: this.$t('broadcasts.broadcastName'), value: 'broadcast_name' },
        { text: this.$t('broadcasts.broadcastTime'), value: 'broadcast_time' },
        { text: this.$t('broadcasts.messageTemplate'), value: 'template', sortable: true },
        { text: this.$t('broadcasts.progress'), value: 'progress', sortable: false },
        {
          text: this.$t('broadcasts.recipients'),
          value: 'recipients',
          sortable: false,
        },
        { text: this.$t('broadcasts.failed'), value: 'failed', sortable: false },
        { text: 'Status', value: 'status', sortable: true },
        { text: this.$t('broadcasts.statistics'), value: 'statistic', sortable: false },
        { text: this.$t('action'), value: 'actions', sortable: false },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.table-broadcast ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
  .v-data-table-header__icon {
    opacity: 1;
  }
}
.chip-margin-left {
  margin-left: 1px;
}
.search-in-detail-status {
  margin-right: 5px;
  margin-bottom: 5px;
}

.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}
</style>
